import React, { Component } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { uploadFile, getUploadedScheduledList } from "../../api/index";
import { refuelserviceOne, pisService } from "../../api/api.config";

import { Upload, Select, Spin, Table, Tag, Popconfirm,Drawer } from "antd";
import { ToastContainer, toast } from "material-react-toastify";
const { Option } = Select;
const { Dragger } = Upload;
const { Column } = Table;


const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: [".xlsx"],
    // multiple: false,

};

export default class scheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedServiceTypeId: 2,
            fileMapperData: null,
            uploadedScheduleList: [],
            errorLogTest: [],
            totalRowCount: 0,
            updaloRowCount: 0,
            FailRoeCount: 0,
            allRowCount: 0,
            errorPopLog: false
        };
    }


    componentDidMount() {
        this.getUploadedScheduledList();
    }

    getUploadedScheduledList = async () => {

        this.showLoading();
        var res = await getUploadedScheduledList();
        console.log(res)
        if (res.success) {
            this.setState({
                uploadedScheduleList: res.data
            });
        }

        this.hideLoading();
    }


    showLoading() {
        this.setState({
            isLoading: true
        })
    }


    hideLoading() {
        this.setState({
            isLoading: false
        })
    }


    onChange = (file) => {
        if (this.state.fileMapperData !== null) {
            this.setState({
                fileMapperData: null
            })
        }
        this.setState({
            fileMapperData: file
        })
    }

    serviceTypesChange = (e) => {
        console.log(e);
        this.setState({
            selectedServiceTypeId: e
        })
    }

    errorLogView = (value) => {
        console.log(value)
        this.setState({
            errorLogTest: value?.errorLogs,
            totalRowCount: value?.uploadedRowCount,
            updaloRowCount: value?.successRowCount,
            FailRoeCount: value?.failedRowCount,
            allRowCount: value?.totalRowCount,
            errorPopLog: true
        })

    }
    onDrawerClose = () => {
        this.setState({
            errorPopLog: false
        })
    }


    submitForm = async () => {
        if (this.state.fileMapperData == null) {
            toast.error("Upload document cannot be null");
            return;
        }
        this.showLoading();
        var url = "";
        var service = "";
        var formdata = new FormData();
        formdata.append("file", this.state.fileMapperData.file);

        switch (this.state.selectedServiceTypeId) {
            case 1:
                url = "/request/uploadPisExcelSheet";
                service = refuelserviceOne;
                break;
            case 2:
                url = "/genServiceRequest/uploadPisExcelSheet";
                service = pisService;
                break;
            case 3:
                url = "/acServiceRequest/uploadPisExcelSheet";
                service = pisService;
                break;
            case 4:
                url = "/siteCleaningService/uploadPisExcelSheet";
                service = pisService;
                break;
            case 5:
                url = "/civilRoutineService/uploadPisExcelSheet";
                service = pisService;
                break;
            default:
                url = "";
                break;
        }
        var res = await uploadFile(service, formdata, url);
        if (res.success) {
            toast.success("File successfully uploded");
            window.location.reload();
        }
        else {
            toast.error("Error, Something went wrong")
        }
        this.hideLoading()
    }


    render() {
        var serviceTypes = [{ "id": 1, "name": "Refuel" }, { "id": 2, "name": "Gen Service - PIS" }, { "id": 3, "name": "AC Service - PIS" }, { "id": 4, "name": "Site Cleaning Service - PIS" }, { "id": 5, "name": "Civil Routine - PIS" }];
        return (
            <Spin spinning={this.state.isLoading} delay={200}>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="page-title-box">
                                <div class="row">
                                    <div class="col">
                                        <h4 class="page-title">Job Scheduler</h4>
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="javascript:void(0);">Jobs </a></li>
                                            <li class="breadcrumb-item"><a href="javascript:void(0);">Schedule Jobs</a></li>

                                        </ol>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                            <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                            <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-sm-1">
                                <label class="form-label" for="exampleInputEmail1">Service Type </label>
                            </div>
                            <div class="col-sm-3">
                                <Select
                                    showSearch
                                    name="regionId"
                                    onChange={(e) => this.serviceTypesChange(e)}
                                    placeholder="Organization"
                                    style={{ width: "100%" }}
                                    value={this.state.selectedServiceTypeId}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {serviceTypes.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                                </div>
                        </div>
                    </div>
                    <div style={{ "marginTop": 30 }}>
                        <Dragger {...props} onChange={this.onChange} beforeUpload={() => false} multiple={false} maxCount={1}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Dragger>
                    </div>
                    <Popconfirm
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={this.submitForm}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button style={{ "marginTop": 20 }} class="btn btn-primary"  >Submit</button>
                    </Popconfirm>
                    <ToastContainer position="top-right" autoClose={5000} />

                    <Table style={{ marginTop: 26, padding: 5 }} 
                    dataSource={this.state.uploadedScheduleList}>
                        <Column title="File Name" dataIndex="fileName" key="fileName" />
                        <Column title="Created Time" dataIndex="createdTime" key="createdTime" render={(value, item, index) => (value.split(".")[0]).replace("T", " ")} />
                        <Column title="Service Type" dataIndex="requestType" key="requestType" />
                        <Column title="Status" dataIndex="uploadedStatus" key="uploadedStatus" render={(value, item, index) => value ? <Tag color='#52c41a'>Successfully Uploded</Tag> : <Tag color='#cd201f'>Upload Fail</Tag>} />
                        <Column title="Error Log" key="errorLogs" render={(value, item, index) => value.errorLogs ? (<a onClick={() => this.errorLogView(value)}> <i class="fa fa-eye" style={{ color: "coral" }} ></i></a>) : ('')} />

                    </Table>
                    <Drawer title={"PIS Schedule Upload - Error Log"} style={{ zIndex: 0 }} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.errorPopLog}>
                            <div class="row mt-4">
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.allRowCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Total</p>
                                </div>
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.totalRowCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Uploaded</p>
                                </div>
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.updaloRowCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Successful</p>
                                </div>
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.FailRoeCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Failed</p>
                                </div>
                            </div>
                            <br></br>
                            <div class="card" style={{ backgroundColor: "#ffe6e6" }}>

                                <div class="card-body">


                                    {this.state.errorLogTest}
                                </div>
                            </div>
                        </Drawer>
                </div ></Spin>
        )
    }
}
