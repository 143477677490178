import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, Input, Tag, Badge, Descriptions, Space, Row, Col, Button, message } from 'antd';
import { Link } from "react-router-dom";
import { EyeFilled } from '@ant-design/icons';
import { getLastThreeRequestDetails, getUserSites, getPisHistoryData, getSitesWithFilter, getPisHistoryDetailsById, getAllPisStatus } from "../../../api/index";
import '../../master-data/style.css';
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux";

class PisHistoryView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            isOpenFillter: true,
            allList: null,
            allSite_loading: true,
            allSite: [],
            depotList: [],
            statusList: [],
            regionsList: [],
            allDataList: [],
            startDate: "",
            endDate: "",
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            comptId: null,
            selectImagesDetails: null,
            regionIds: '',
            depotIds: '',
            siteIds: '',
            statusId: '',
            enterReqCode: '',
            fullPageCount: 0,
            loopPageCount: 0,
            recodesPerPage: 50,
            itemDetails: [],
            selectedServiceTypeId: 1,
            selectedDataUrl: "/genServiceRequest/getAllPisGenServiceRequests",
            selectedDetailUrl: "",
            recodesPerPage2: 1,
            pageNo2: 0,
            filterVisibility: false,
            currentPage: 0,
            pageCount: 0,
            currentTablePage: 0,
        };

    }
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }

    previousBtnClick2 = () => {
        if (this.state.pageNo2 > 0) {
            var aa = this.state.pageNo2 - 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })
        }
    }
    nextBtnClick2 = () => {
        if (this.state.recodesPerPage2 > this.state.pageNo2 + 1) {
            var aa = this.state.pageNo2 + 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })
        }
    }

    fetchNextData = (currentPageNo) => {
        this.setState({
            currentTablePage: currentPageNo
        })
        let totalTablePages = Math.ceil(this.state.allDataList.length / 10);
        if (currentPageNo === totalTablePages) {
            if (this.state.currentPage < this.state.pageCount) {
                this.getPisDataList();
            }
        }
    }
    
    componentDidMount() {
        this.getAllDataPisStatus();
        this.getDataSitesByuser()
        var _depotList = [];
        var _regionsList = [];
        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
    }

    getDataSitesByuser = async () => {
        var res = await getUserSites();
        var _allSite = [];
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });
        }
    };

    getAllDataPisStatus = async () => {
        var res = await getAllPisStatus();
        if (res.success) {
            var _statudList = [];
            res.data.map((depot) => {
                _statudList.push({ value: depot.id, label: depot.name })
            })
            this.setState({
                statusList: _statudList,
            })
        }
    }

    getPisDataList = async () => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("pageNo", this.state.currentPage + 1);
        data.append("rowCountPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);
        data.append('status', this.state.statusId);
        data.append('serviceRequestIds', this.state.enterReqCode);
        var pageCount = 0;
        var res = await getPisHistoryData(this.state.selectedDataUrl, data);
        if (res.success) {
            this.setState(prevState => ({
                loading: false,
                tabaleLoading: false,
                allDataList: [...prevState.allDataList, ...res.data.content.reverse()],
                pageCount: res.data.totalPages,
                currentPage: this.state.currentPage + 1,
                recodesPerPage2: res.data?.totalPages
            }));
        }
    }

    clearBtnClick = () => {
        window.location.reload()
    }

    searchRegionmChange = (event) => {
        var dd = event
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];
        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })
        this.setState({
            regionIds: subRegionIdArray
        }, () => { this.getDataSitesWithFilter() });
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })
        this.setState({
            depotList: _depotList1
        })
    }

    searchDepottemChange = (event) => {
        this.setState({
            depotIds: event
        }, () => this.getDataSitesWithFilter());
    }

    getDataSitesWithFilter = async () => {
        this.setState({
            allSite_loading: true,
        })
        var _regionList = [];
        var _depotList = [];
        var _allSite = [];
        if (this.state.regionIds !== '') {
            this.state.regionIds?.map((x, y) => {
                _regionList.push({ "id": x })
            }, () => {

            })
        }
        if (this.state.depotIds !== '') {
            this.state.depotIds?.map((a, b) => {
                _depotList.push({ "id": a })
            }, () => {
            })
        }
        var data = new FormData();
        data.append('regions', JSON.stringify(_regionList));
        data.append('depots', JSON.stringify(_depotList));
        var res = await getSitesWithFilter(data);
        if (res.success) {
            res.data.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            })
        }
    }

    checkFilterDisplay = () => {
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.setState({
                filterVisibility: true
            })
        } else {
            this.setState({
                filterVisibility: false,
                regionIds: '',
                depotIds: '',
                siteIds: '',
                statusId: '',
            })
        }
    }

    onChangeDate = (date, dateString) => {
        this.setState({
            startDate: dateString,
        }, () => {
            this.checkFilterDisplay();
        });
    };

    onChangeDate2 = (date, dateString) => {
        this.setState({
            endDate: dateString,
        }, () => {
            this.checkFilterDisplay();
        });
    };

    searchStatusChange = (event) => {
        this.setState({
            statusId: event
        });
    }

    searchSiteemChange = (event) => {
        this.setState({
            siteIds: event
        });
    }

    filterBtnClick = async () => {
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.getPisDataList();
        } else {
            if (this.state.enterReqCode != '') {
                if (this.state.selectedDataUrl == '/genServiceRequest/getAllPisGenServiceRequests') {
                    let reqCodeDataUrl = '/genServiceRequest/getAllPisGenServiceRequestsByRequestCode'
                    this.getPisDataListByRequestCode(this.state.enterReqCode, reqCodeDataUrl)
                }
                if (this.state.selectedDataUrl == '/acServiceRequest/getAllPisAcServiceRequests') {
                    let reqCodeDataUrl = '/acServiceRequest/getAllPisAcServiceRequestsByRequestCode'
                }
                if (this.state.selectedDataUrl == '/siteCleaningService/getAllPisScServiceRequests') {
                    let reqCodeDataUrl = '/siteCleaningService/getAllPisScServiceRequestsByRequestCode'
                }

                if (this.state.selectedDataUrl == '/civilRoutineService/getAllPisCrServiceRequests') {
                    let reqCodeDataUrl = '/civilRoutineService/getAllPisCrServiceRequestsByRequestCode'
                }
            }
        }
    }

    getPisDataListByRequestCode = async (code, url) => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("serviceRequestIds", code);
        var res = await getPisHistoryData(url, data);
        if (res.success) {
            this.setState({
                loading: false,
                tabaleLoading: false,
                allDataList: res.data.content.reverse(),
                recodesPerPage2: res.data?.totalPages
            })
        }
    }

    serviceTypesChange = (e, serviceTypes) => {
        this.setState({
            currentPage: 0,
            pageCount: 0,
            currentTablePage: 0,
            selectedServiceTypeId: e,
            selectedDataUrl: serviceTypes[e - 1].data_url
        })
    }

    searchFormReqCode = (event) => {
        this.setState({
            enterReqCode: event.target.value
        })
    }

    render() {
        const { Option } = Select;
        const columns123 = [
            {
                title: "Id",
                render: (item) => <>
                    <span style={{ padding: 1 }} key={item?.id}>
                        {item?.id}
                    </span>
                </>,
            },
            {
                title: "Request Code",
                render: (item) => <>

                    {item?.requestCode}
                </>,
            },
            {
                title: "Site Id",
                render: (item) => <>
                    {item?.siteId}
                </>,
            },
            {
                title: "Site Name",
                render: (item) => <>
                    {item?.siteName}
                </>,
            },
            {
                title: "Created Date",
                render: (item) => <>
                    <>
                        {item?.createdDate.split("T")[0]}
                    </>
                </>,
            },
            {
                title: "Status",
                render: (item) => <>
                    <Tag color={item?.colorCode.replace("0xFF", "#")} style={{ padding: 5, borderRadius: 5 }} >
                        {item.status}
                    </Tag>
                </>,
            },
            {
                title: "Service Date",
                render: (item) => <>
                    <>
                        {item?.serviceDate == null ? "PENDING" : item?.serviceDate}
                    </>
                </>,
            },
            {
                title: 'Action',
                key: 'action',
                render: (item) => (
                    <>
                        {
                            <a href={this.state.selectedServiceTypeId === 4 ? "/pis/detailView/civil/" + item.id + "/" + this.state.selectedServiceTypeId :
                                "/pis-detail-view/" + item.id + "/" + this.state.selectedServiceTypeId}>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '25px', height: '25px', borderRadius: '50%', backgroundColor: '#f0f0f0' }}>
                                    <EyeFilled style={{ fontSize: '15px', color: '#1890ff' }} />
                                </div>
                            </a>
                        }
                    </>
                )
            },
        ];

        var serviceTypes = [
            { 
                "id": 1, 
                "name": "Gen Service - PIS", 
                "data_url": "/genServiceRequest/getAllPisGenServiceRequests" 
            }, 
            { 
                "id": 2, 
                "name": "AC Service - PIS", 
                "data_url": "/acServiceRequest/getAllPisAcServiceRequests" 
            }, 
            { 
                "id": 3, 
                "name": "Site Cleaning Service - PIS", 
                "data_url": "/siteCleaningService/getAllPisScServiceRequests" 
            }, 
            { 
                "id": 4, 
                "name": "Civil Routine - PIS", 
                "data_url": "/civilRoutineService/getAllPisCrServiceRequests" 
            }
        ];

        return (
            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <div className="col">
                                        <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>PIS Data View</span>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a className='textStyles-small'>PIS</a></li>
                                            <li className="breadcrumb-item"><a className='textStyles-small'>PIS Data View</a></li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="col-auto align-self-center" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" style={{ padding: 10, borderRadius: 12 }}>
                    <Row span={24} style={{ width: '100%' }}>
                        <Col span={6}>                                            
                        <Select
                            showSearch
                            name="regionId"
                            onChange={(e) => this.serviceTypesChange(e, serviceTypes)}
                            placeholder="Organization"
                            style={{ width: "98%", height: 'auto', marginTop: 10 }}
                            className='borderedSelect'
                            bordered={false}
                            value={this.state.selectedServiceTypeId}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {serviceTypes.map((item, index) => {
                                return <Option key={index} value={item.id}>{item.name}</Option>;
                            })}
                        </Select></Col>
                        <Col span={6}>                                           
                        <DatePicker
                            placeholder="Start Date *"
                            style={{ width: "98%", height: '34px', marginTop: 10 }}
                            className='borderedSelect'
                            onChange={this.onChangeDate}
                        /></Col>
                        <Col span={6}>                                            
                        <DatePicker
                            placeholder="End Date *"
                            style={{ width: "98%", height: '34px', marginTop: 10 }}
                            className='borderedSelect'
                            onChange={this.onChangeDate2}
                        /></Col>
                        <Col span={6}>                                            
                        <Input
                            style={{ width: "98%", height: 'auto', marginTop: 10, height: '34px' }}
                            className='borderedSelect'
                            placeholder="Request Code" onChange={(e) => this.searchFormReqCode(e)} /></Col>

                        {this.state.filterVisibility ? (<>
                            <Col span={6}>                                                <Select
                                mode="multiple"
                                showSearch
                                name="regionId"
                                onChange={(e) => this.searchRegionmChange(e)}
                                placeholder="Region"
                                style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                className='borderedSelect'
                                bordered={false}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.regionsList?.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select></Col>
                            <Col span={6}>                                                <Select
                                mode="multiple"
                                showSearch
                                name="depotId"
                                onChange={(e) => this.searchDepottemChange(e)}
                                placeholder="Depot"
                                style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                className='borderedSelect'
                                bordered={false}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }

                            >
                                {this.state.depotList?.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select></Col>
                            <Col span={6}>                                                <Select
                                mode="multiple"
                                placeholder="Site"
                                style={{ width: "98%", height: 'auto', marginTop: 10 }}
                                className='borderedSelect'
                                bordered={false}
                                loading={this.state.allSite_loading}
                                name="siteId"
                                onChange={(e) => this.searchSiteemChange(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA?.children?.toLowerCase()?.localeCompare(optionB?.children?.toLowerCase())
                                }
                            >
                                {this.state.allSite.map((item, index) => {
                                    return <Option value={item?.value}>{item?.label}</Option>;
                                })}
                            </Select></Col>
                            <Col span={6}></Col>
                        </>) : ('')}

                        <Col span={6}>
                            <Button
                                style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '120px', height: '34px', marginTop: 10 }}
                                onClick={() => this.filterBtnClick()}><span className="textStyles-small" style={{ color: 'white', fontSize: 12 }}>Filter</span>
                            </Button>
                            <Button
                                type="button"
                                class="btn btn-soft-secondary btn-sm"
                                onClick={() => this.clearBtnClick()}
                                style={{ borderColor: '#CCCCCC', backgroundColor: '#CCCCCC', borderRadius: '5px', width: '120px', height: '34px', marginTop: 10, marginLeft: 5 }}
                            >
                                <span className="textStyles-small" style={{ color: 'black', fontSize: 13 }}>Reset</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div class="card" style={{ padding: 10, borderRadius: 12, marginTop: 10 }}>
                    <Table
                        size="small"
                        className="table-striped-rows textStyles-small"
                        fontSize={9}
                        columns={columns123}
                        bordered={false}
                        loading={this.state.loading}
                        dataSource={this.state.allDataList}
                        onChange={(pagination, filters, sorter) => {
                            this.fetchNextData(pagination?.current);
                        }}
                        pagination={{
                            defaultPageSize: 10,
                        }}
                    />

                    {/* <Table
                        size='small'
                        fontSize={9}
                        columns={columns123}
                        bordered
                        pagination={true}
                        loading={this.state.loading}
                        dataSource={this.state.allDataList}
                        onChange={this.fetchNextData}
                    /> */}

                    {/* <div class="row">

                        <div class=" col-md-12">
                            <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" id="row_callback_previous">
                                        <a onClick={() => this.previousBtnClick2()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                    </li>
                                    <li class="paginate_button page-item ">
                                        <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link">{this.state.pageNo2 + 1}/{this.state.recodesPerPage2}
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" id="row_callback_next">
                                        <a onClick={() => this.nextBtnClick2()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link">Next
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        depots: state.depots,
        regions: state.regions
    };
}
export default withRouter(connect(mapStateToProps, {})(PisHistoryView));


